import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/globals.css';
import moment from 'moment-timezone';
import Head from 'next/head';

moment.locale('pt-br');

function App({ Component, pageProps, err }) {
  const settings = pageProps?.defaultSettings;

  return (
    <>
      <Head>
        <title>
          {settings?.nome}
          {settings?.branding ? ' | Franquia Bet' : ''}
        </title>
        <meta
          name="og:title"
          content={
            settings?.branding
              ? `${settings?.nome} | Franquia Bet`
              : `${settings?.nome}`
          }
        />
        <meta property="og:type" content="website" />
        {settings?.preview_image && (
          <meta property="og:image" content={settings?.preview_image} />
        )}
        <meta name="og:description" content={settings?.descricao} />
        <meta name="keywords" content={settings?.chaves} />
        <meta name="description" content={settings?.descricao} />
        <link rel="icon" href={settings?.favicon} />
        <link rel="stylesheet" href={`${process.env.API_URL}/custom_css/`} />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.11.0/css/all.css"
        />
        <link
          rel="stylesheet"
          href="//cdn.jsdelivr.net/npm/alertifyjs@1.13.1/build/css/alertify.min.css"
        />
        <link
          rel="stylesheet"
          href="//cdn.jsdelivr.net/npm/alertifyjs@1.13.1/build/css/themes/default.min.css"
        />
        <script src="//cdn.jsdelivr.net/npm/alertifyjs@1.13.1/build/alertify.min.js" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                page_path: window.location.pathname
              });
            `
          }}
        />
        {process.env.NEXT_PUBLIC_GOOGLE_ADS ? (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  gtag('js', new Date());
                  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS}');
                `
              }}
            />
            <script dangerouslySetInnerHTML={{__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ADS}');`}} />
          </>
        ) : null}
        {process.env.NEXT_PUBLIC_FB_PIXEL_ID &&
          <noscript>
            <img
              height="1"
              width="1"
              style={{display: 'none'}}
              src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FB_PIXEL_ID}&ev=PageView&noscript=1`}
            />
          </noscript>
        }
      </Head>
      {process.env.NEXT_PUBLIC_FB_PIXEL_ID &&
        <script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID}');
              fbq('track', 'PageView');
            `,
          }}
        />
      }
      {process.env.NEXT_PUBLIC_GOOGLE_ADS &&
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS}`}
            height="0"
            width="0"
            style={{display: 'none', visibility: 'hidden'}}>
          </iframe>
        </noscript>
      }
      <Component {...pageProps} err={err} />
    </>
  );
}

export default App;
